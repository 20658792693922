.first-block {
  @apply w-full relative z-50;
  height: 37.5rem;
  // height: 600px;

  &::after {
    content: "";
    @apply absolute block rounded-full;
    background: inherit;
    // width: calc(var(--fullwidth) + 40px);
    width: calc(var(--fullwidth) + 2.5rem);
    height: 7.5rem;
    // height: 120px;
    bottom: -4.375rem;
    // bottom: -70px;
    left: -1.25rem;
    // left: -20px;
    border-radius: 100%;
    z-index: -1;
  }
}

.second-block {
  @apply block relative w-full bg-white z-40;
  height: 50%;
  // padding-top: 150px;
  padding-top: 14.375rem;
  // padding-top: 230px;
}

.middle-block {
  @apply block w-full text-center;
  padding: 5rem 0 7.5rem 0;
  // padding: 80px 0 120px 0;
}

.third-block {
  @apply w-full relative z-50;
  height: 37.5rem;
  // height: 600px;

  &::before {
    content: "";
    @apply absolute block w-full left-0;
    background: inherit;
    // width: calc(var(--fullwidth) + 40px);
    width: calc(var(--fullwidth) + 2.5rem);
    height: 7.5rem;
    // height: 120px;
    top: -2.5rem;
    // top: -40px;
    left: -1.25rem;
    // left: -20px;
    border-radius: 100%;
    z-index: -1;
  }
}

.ski-success-block {
  @apply bg-green w-full relative z-50;
  height: 200px;
  // height: 36.25rem;
  // height: 580px;

  &::before {
    content: "";
    @apply absolute block left-0;
    background: inherit;
    width: calc(var(--fullwidth) + 3.75rem);
    // width: calc(var(--fullwidth) + 60px);
    height: 7.5rem;
    // height: 120px;
    top: -2.5rem;
    // top: -40px;
    left: -1.875rem;
    // left: -30px;
    border-radius: 100%;
    z-index: -1;
  }
}
.ski-thanks-block {
  @apply w-full relative z-50;
  height: 160px;

  &::before {
    content: "";
    @apply absolute block left-0;
    background: inherit;
    width: calc(var(--fullwidth) + 3.75rem);
    height: 7.5rem;
    top: -2.5rem;
    left: -1.875rem;
    border-radius: 100%;
    z-index: -1;
  }
}
.ski-summary-block {
  @apply w-full relative z-50;
  height: 90px;

  &::before {
    content: "";
    @apply absolute block left-0;
    background: inherit;
    width: calc(var(--fullwidth) + 3.75rem);
    height: 7.5rem;
    top: -2.5rem;
    left: -1.875rem;
    border-radius: 100%;
    z-index: -1;
  }
}

.success-block {
  @apply bg-green w-full relative z-50;
  height: 50vh;
  // height: 36.25rem;
  // height: 580px;

  &::before {
    content: "";
    @apply absolute block left-0;
    background: inherit;
    width: calc(var(--fullwidth) + 3.75rem);
    // width: calc(var(--fullwidth) + 60px);
    height: 7.5rem;
    // height: 120px;
    top: -2.5rem;
    // top: -40px;
    left: -1.875rem;
    // left: -30px;
    border-radius: 100%;
    z-index: -1;
  }
}

.error-block {
  @apply w-full relative z-50 transition-all;
  height: 50vh;
  // height: 36.25rem;
  // height: 580px;

  &-expanded {
    height: 60vh;
  }

  // &::before {
  //   content: "";
  //   @apply absolute block left-0;
  //   background: inherit;
  //   width: calc(var(--fullwidth) + 3.75rem);
  //   height: 7.5rem;
  //   top: -2.5rem;
  //   left: -1.875rem;
  //   border-radius: 100%;
  //   z-index: -1;
  // }
}
.error-desc {
  @apply min-h-96 translate-y-96;
  // min-height: 400px;
  // margin-bottom: -400px;
  // transform: translateY(400px);

  &-expanded {
    @apply translate-y-0;
    // transform: translateY(0px);
  }
}
.attention-block {
  @apply bg-yellow-400 w-full relative z-50;
  height: 50vh;
  // height: 36.25rem;
  // height: 580px;

  &::before {
    content: "";
    @apply absolute block left-0;
    background: inherit;
    width: calc(var(--fullwidth) + 3.75rem);
    // width: calc(var(--fullwidth) + 60px);
    height: 7.5rem;
    // height: 120px;
    top: -2.5rem;
    // top: -40px;
    left: -1.875rem;
    // left: -30px;
    border-radius: 100%;
    z-index: -1;
  }
}

.error-swipe-block {
  @apply w-full relative z-50 transition-all min-h-24 translate-y-74;
  // min-height: 100px;
  // transform: translateY(300px);

  &-expanded {
    @apply h-auto mt-8 translate-y-0;
    // height: auto;
    // margin-top: 2rem;
    // transform: translateY(0px);
  }

  &::before {
    content: "";
    @apply absolute block w-full left-0;
    background: inherit;
    // width: calc(var(--fullwidth) + 40px);
    width: calc(var(--fullwidth) + 2.5rem);
    height: 7rem;
    top: -2.8rem;
    left: -1.25rem;
    border-radius: 100%;
    z-index: -1;
  }
}

.middle-block_v2 {
  position: relative;
  height: auto;

  // &:after {
  //   @apply absolute block rounded-full;

  //   background: inherit;
  //   height: 4rem;
  //   bottom: -2.375rem;
  //   content: "";
  //   background: inherit;
  //   width: calc(var(--fullwidth) + 2.5rem);
  //   left: -1.25rem;
  //   border-radius: 100%;
  //   z-index: -1;
  // }
}
