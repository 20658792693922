.hand-icon {
  position: relative;
  background-image: url("https://i.postimg.cc/8556gm60/hand.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  width: 250px;
  height: 250px;
  animation: handspin 2s infinite;
  transform-origin: 52% 62%;
}

@keyframes handspin {
  0% {
    transform: rotate(30deg);
    margin-left: 20px;
  }
  50% {
    transform: rotate(-15deg);
    margin-left: -20px;
  }
  100% {
    transform: rotate(0deg);
    margin-left: 20px;
  }
}
