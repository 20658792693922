:root {
  --font: "Barlow Condensed", sans-serif;
  --fullwidth: 100vw;
  --fullheight: 100vh;
  --primary-dark: #262626;
  --primary-success: #5ba342;
}

$progress-bar-stroke-width: 2;
$progress-bar-size: 16rem;

$sanusx-progress-bar-stroke-width: 2;
$sanusx-progress-bar-size: 16rem;

// font-family: 'Frank Ruhl Libre', serif;
// font-family: 'Roboto', sans-serif;
// font-family:  "Barlow Condensed", sans-serif;
