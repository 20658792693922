.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-full {
  grid-column: 1/-1;
}
.col-span-7 {
  grid-column: span 7 / span 7;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-4 {
  grid-row: span 4 / span 4;
}
