.product_item_sanusx {
  @apply w-67 h-auto bg-white border-2  flex flex-col items-start justify-between;

  .pi-image {
    @apply w-full h-56 mt-2 flex flex-col items-center justify-center bg-white rounded-card;
  }

  .pi-title {
    @apply w-full h-auto px-4 pt-2 flex flex-col items-start justify-start;
  }

  &:active {
    @apply border-gray-light drop-shadow-xl scale-95;
  }
}
