.btn {
  @apply outline-none ring-0 transition-all ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:focus {
  }

  &:active {
  }

  &.finger-ssaver {
    // @apply block absolute text-gray scale-90 opacity-40;
    // width: 20.563rem;
    // bottom: -10.625rem;
    // right: 5%;
    // z-index: 45;
    // margin-left: -4rem;
    @apply block relative text-gray scale-90 drop-shadow-sm;
    width: 20.563rem;

    z-index: 45;
    animation: pulse 6s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    &:enabled:active {
      @apply drop-shadow-xl scale-75;
    }

    &--portrait {
      right: 15%;
    }

    &--medium {
      @apply opacity-40 scale-50;
      bottom: 1.875rem;
      right: 25%;

      svg {
        @apply scale-100;
      }
    }
    &--big {
      @apply opacity-40;
      bottom: 12.5rem;
      right: 25%;

      svg {
        @apply scale-100;
      }
    }
  }

  &.btn-round-big {
    @apply w-68 h-auto flex flex-col mx-auto;
    // mx-2
    .icon {
      @apply w-68 h-68 p-6 max-w-68 max-h-68 flex items-center flex-col justify-center bg-white overflow-hidden  rounded-full text-white;
      border-width: 10px;

      .icon-in {
        @apply w-full h-full flex flex-col items-center text-center justify-center  text-white rounded-full overflow-hidden;

        svg {
          @apply w-24 h-24 mx-auto;
        }
      }
    }

    .label {
      @apply text-center w-full uppercase mt-8  font-semibold text-5xl;
    }

    &:active {
      .icon {
        @apply drop-shadow-xl scale-95;

        .icon-in {
          // @apply bg-primary-accent;
        }
      }

      .label {
        // @apply text-primary-accent;
      }
    }
  }
  &.btn-round-sc-lg {
    @apply w-60 h-auto flex flex-col mx-auto;
    // mx-2
    .icon {
      @apply w-60 h-60 p-6 max-w-60 max-h-60 flex items-center flex-col justify-center bg-white overflow-hidden  rounded-full text-white;
      border-width: 10px;

      .icon-in {
        @apply w-full h-full flex flex-col items-center text-center justify-center  text-white rounded-full overflow-hidden;

        svg {
          @apply w-24 h-24 mx-auto;
        }
        .text-primaryx,
        .text-active {
          color: #ffffff !important;
        }
      }
    }

    .label {
      @apply text-center w-full uppercase mt-8  font-semibold text-4xl;
    }

    &:active {
      .icon {
        @apply drop-shadow-xl scale-95;

        .icon-in {
          // @apply bg-primary-accent;
        }
      }

      .label {
        // @apply text-primary-accent;
      }
    }
  }
  &.btn-round-sc-sm {
    @apply w-52 h-auto flex flex-col mx-auto;
    // mx-2
    .icon {
      @apply w-52 h-52 p-3 max-w-52 max-h-52 flex items-center flex-col justify-center bg-white overflow-hidden  rounded-full text-white;
      border-width: 10px;

      .icon-in {
        @apply w-full h-full flex flex-col items-center text-center justify-center  text-white rounded-full overflow-hidden;

        svg {
          @apply w-20 h-20 mx-auto;
        }
        .text-primaryx,
        .text-active {
          color: #ffffff !important;
        }
      }
    }

    .label {
      @apply text-center w-full uppercase mt-6 mb-6 font-semibold text-4xl;
    }

    &:active {
      .icon {
        @apply drop-shadow-xl scale-95;

        .icon-in {
          // @apply bg-primary-accent;
        }
      }

      .label {
        // @apply text-primary-accent;
      }
    }
  }

  &.btn-side {
    @apply w-full relative min-h-29 py-6 space-y-3 text-white flex flex-col items-center justify-center;
    .icon {
      @apply text-white;
    }

    .label {
      @apply text-white text-xl uppercase font-medium;

      &--middle {
        @apply text-3xl;
      }
    }

    &:before {
      content: "";
      @apply block absolute w-6 h-full top-0 left-0 -ml-4;
      background: inherit;
    }

    &:active {
      @apply drop-shadow-xl scale-95;

      .icon {
      }

      .label {
      }

      &:before {
        background: inherit;
      }
      &:after {
        background: inherit;
      }
    }

    &.btn-side-r {
      @apply rounded-r-2xl;

      &:before {
        @apply left-0 -ml-4;
      }
      &:after {
        @apply hidden;
      }
    }

    &.btn-side-l {
      @apply rounded-l-2xl;

      &:before {
        @apply hidden;
      }

      &:after {
        @apply right-0 -mr-4;
      }
    }

    &--middle {
      svg {
        width: 80%;
        height: 80%;
      }
    }
  }

  &.btn-back {
    @apply w-32 h-32 py-6 mb-3 space-y-3  rounded-2xl text-white flex flex-col items-center justify-center;
    .icon {
      @apply text-white;

      svg {
        width: 70%;
        height: 70%;
      }
    }

    .label {
      @apply text-white text-xl uppercase font-medium;
    }

    &:active {
      @apply drop-shadow-xl scale-95;
    }
  }

  &.btn-category {
    @apply w-full h-auto  min-h-14 mb-2 px-3 py-2 flex flex-row items-center justify-between bg-white-30 border-3 rounded-xl;

    .label {
      @apply text-xl text-left font-semibold;
    }

    .icon {
      // @apply -mr-5;
    }

    &.cat-sideline {
      @apply relative rounded-b-none mb-0 border-b-gray/10;

      &:before {
        @apply hidden  w-[3px] h-full absolute -left-[3px] top-[45%] bg-gray-light;
        content: "";
      }
    }

    &.activated {
      // @apply border-gray;
      .icon {
        // @apply text-gray;
      }
    }

    &:active {
      @apply drop-shadow-xl;
    }

    &:disabled {
      @apply cursor-not-allowed;
    }

    &--parent {
      @apply uppercase bg-gray/20 z-30;
    }

    &--child {
      @apply w-full ml-auto relative z-5 m-0;
      border-width: 0px;
      border-bottom-width: 3px;
      background: transparent;
      border-radius: 0;

      &:before {
        @apply w-[3px] h-[130%] bg-gray-light transition-all hidden absolute -left-[10px] -top-[98%] z-[-1];
        content: "";
      }

      &:after {
        @apply w-2 h-[3px] bg-gray-light transition-all hidden absolute -left-2 top-[25%] z-[-1];
        content: "";
      }

      .icon {
        // @apply hidden;
        @apply absolute right-0 w-12 h-12 -z-1 opacity-40;
      }

      &:active {
        &:before {
          @apply w-5 -left-5;
        }
      }
    }

    &--middle {
      @apply h-22 min-h-22;

      .label {
        @apply text-3xl;
      }

      .icon {
        @apply -mr-8;
      }
    }
  }
  &.btn-nex-category {
    @apply w-full h-auto min-h-14 mb-2 pl-4 pr-2 flex flex-col items-center justify-between rounded-l-xl;

    .label {
      @apply w-full text-xl text-left font-semibold pb-2 text-nex-gray-dark min-h-14 h-14 flex items-center justify-start;
    }

    .icon {
      // @apply -mr-5;
    }

    &.cat-sideline {
      @apply relative rounded-b-none mb-0 border-red;
    }

    &.activated {
      // @apply border-gray;
      .icon {
        // @apply text-gray;
      }
    }

    &:active {
      @apply drop-shadow-xl;
    }

    &:disabled {
      @apply cursor-not-allowed;
    }

    &--parent {
      @apply uppercase bg-white z-30;
    }

    &--child {
      @apply w-full ml-auto relative z-5 m-0 bg-white;

      .icon {
        @apply absolute right-0 w-12 h-12 -z-1 opacity-40;
      }

      &:active {
        &:before {
          @apply w-5 -left-5;
        }
      }
    }

    &--middle {
      @apply h-22 min-h-22;

      .label {
        @apply text-3xl;
      }

      .icon {
        @apply -mr-8;
      }
    }
  }
  &.btn-top {
    @apply w-57 h-16 min-h-16 px-5 flex flex-row items-center justify-start bg-gray-ultra border-3 border-primary-bg rounded-xl;

    .icon {
      @apply mr-4;
    }

    .label {
      @apply text-gray text-2xl uppercase text-left font-semibold tracking-wide;
    }

    &:active {
      @apply drop-shadow-xl scale-95;
    }

    &.btn-top-short {
      @apply w-22 justify-center;

      .icon {
        @apply mr-0;
      }

      .label {
        @apply hidden;
      }
    }
  }
  &.btn-card-cart {
    @apply w-full h-12  text-center  text-white flex flex-col items-center justify-center rounded-xl;

    .label {
      @apply hidden;
    }
    .icon {
      @apply mx-auto;
    }

    &:active,
    &:focus {
      @apply drop-shadow-xl scale-95;
    }
  }

  &.btn-card-cart-with-label {
    @apply w-full h-14  text-center  text-white flex flex-row items-center justify-start rounded-xl;

    .label {
      @apply text-xl flex-1 text-left pl-3 pr-3;
    }

    .icon {
      @apply w-14 ml-auto text-center flex flex-col items-center justify-center;
    }

    &:active {
      @apply drop-shadow-xl scale-95;
    }
  }

  &.btn-mini-action {
    @apply w-16 h-16 rounded-full flex p-1 border-5  flex-col items-center justify-center text-center;
    .icon {
      @apply w-full h-full rounded-full flex  justify-center items-center text-white;

      svg {
        height: 70%;
        width: 70%;
      }
    }

    &:active {
      @apply drop-shadow-xl scale-95;

      .icon {
        // @apply bg-primary-accent;
      }
    }

    &--middle {
      @apply w-25 h-25;
    }

    &--primary {
      // @apply border-primary;

      .icon {
        // @apply bg-primary;
      }
    }

    &--photo {
      @apply bg-white border-gray-light;
      .icon {
        @apply bg-gray-light;
      }

      &-prev {
        @apply relative rounded-r-full rounded-l-none border-l-white;

        &:before {
          content: "";
          @apply absolute w-1 h-full bg-white left-0 top-0 z-60;
        }

        &:active {
          @apply rounded-r-full rounded-l-none border-l-white scale-100 border-gray-light drop-shadow-none;
        }
      }

      &-next {
        @apply relative rounded-l-full rounded-r-none border-r-white;

        &:after {
          content: "";
          @apply absolute w-2 h-full bg-white -right-2 top-0 z-60;
        }

        &:active {
          @apply rounded-l-full rounded-r-none border-r-white scale-100 border-gray-light drop-shadow-none;
        }
      }

      &:active {
        .icon {
          @apply scale-90 drop-shadow-sm;
        }
      }
    }

    &:disabled {
      @apply cursor-not-allowed opacity-20;
    }
  }

  &.btn-action {
    @apply min-w-56 w-56 h-18 rounded-full flex p-1 border-5 flex-row items-center justify-start text-left;

    .icon {
      @apply w-14 h-14 rounded-full flex justify-center items-center text-white mr-4;
    }

    .label {
      @apply text-xl uppercase font-bold text-left;
    }

    &:active {
      @apply drop-shadow-xl scale-95;

      .icon {
        // @apply bg-primary-accent;
      }

      .label {
        // @apply text-primary-accent;
      }
    }

    &--info {
      // @apply border-gray-light;

      .icon {
        // @apply bg-gray-light;
      }

      .label {
        // @apply text-gray-light;
      }
    }

    &--primary {
      // @apply border-primary;

      .icon {
        // @apply bg-primary;
      }
      .label {
        // @apply text-primary;
      }
    }

    &--success {
      @apply text-right justify-end;

      .icon {
        @apply mr-0 ml-4 order-2;
      }
      .label {
        @apply order-1 text-right;
      }
    }

    &--success-long {
      @apply w-96 text-right justify-end;

      .icon {
        @apply mr-0 ml-4 order-2;
      }
      .label {
        @apply order-1 text-right pl-4;
      }
    }

    &--buy-big {
      @apply w-full max-w-full h-22;

      .icon {
        @apply ml-6 mr-6 w-16 h-16  text-white;
      }
      .label {
        @apply text-2xl text-white tracking-wide;
      }

      &:active {
        // @apply bg-primary-accent border-primary-accent;

        .icon {
          // @apply bg-primary-accent;
        }

        .label {
          // @apply text-white;
        }
      }
    }
    &--buy-big-ultra {
      @apply w-full max-w-full h-22 justify-center items-center;

      .icon {
        @apply ml-6 mr-6 w-16 h-16  text-white;
      }

      .label {
        @apply text-3xl tracking-wider font-bold;
      }

      &:active {
        // @apply bg-primary-accent border-primary-accent;

        .icon {
          // @apply bg-primary-accent;
        }

        .label {
          // @apply text-white;
        }
      }
    }
    &--middle {
      @apply border-3 min-w-64 w-64;

      .icon {
        @apply w-12 h-12;

        svg {
          width: 70%;
          height: 70%;
        }
      }

      .label {
        @apply text-3xl;
      }
    }
  }
}

.under-cart-btn {
  @apply w-full h-20 flex text-lg font-bold items-center justify-center bg-gray-light-50 -mt-4 pt-2 z-5 relative;

  &-r {
    @apply rounded-br-2xl;
  }
  &-l {
    @apply rounded-bl-2xl;
  }
}

.cart-quantity {
  @apply w-12 h-12 absolute rounded-full bg-green text-white flex text-2xl items-center text-center justify-center;
  right: 2.9rem;
  bottom: -1.5rem;
}

li.subcat-list {
  @apply relative;

  .cat-point {
    @apply absolute w-2 h-4 bg-gray/10 left-0 rounded-r-xl top-[37%];
  }

  &:last-child {
    .btn-category--child {
      @apply border-b-0;
    }
  }
}
