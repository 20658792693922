.default-slide {
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
    box-shadow: none !important;
    border-radius: 20px !important;
    border: 5px solid #f5f5fd !important;
  }

  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img,
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div video {
    border-radius: 20px !important;
  }
}

.sanusx-slide {
  .ng-image-slider {
    height: 100% !important;
  }

  .ng-image-slider .ng-image-slider-container .main {
    height: 100% !important;
  }

  .ng-image-slider .ng-image-slider-container .main .main-inner {
    width: 100% !important;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
  .ng-image-slider
    .ng-image-slider-container
    .main
    .main-inner::-webkit-scrollbar {
    display: none !important;
  }

  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: 0.125rem solid #cdcee4 !important;
    width: 5.625rem !important;
    height: 5.625rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0.938rem !important;
  }
  .ng-image-slider
    .ng-image-slider-container
    .main
    .main-inner
    .img-div
    .custom-image-main {
    width: 5.625rem !important;
    height: 5.625rem !important;
  }

  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img,
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div video {
    border-radius: 0px !important;
  }
}

.nex-slide {
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
    @apply border-4 border-nex-gray-light rounded-xl shadow-none #{!important};
  }

  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img,
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div video {
    @apply rounded-none #{!important};
  }
}
