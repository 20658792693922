.btn-sanusx {
  @apply outline-none ring-0 transition-all ease-in-out disabled:opacity-30 disabled:blur-sm;
  -webkit-tap-highlight-color: transparent;

  &:focus {
  }

  &:enabled:active {
  }

  &.finger-ssaver {
    // @apply block absolute text-gray scale-90 opacity-40;
    // width: 20.563rem;
    // bottom: -10.625rem;
    // right: 5%;
    // z-index: 45;
    // margin-left: -4rem;
    @apply block relative  scale-90 opacity-40;
    width: 20.563rem;
    z-index: 45;
    animation: pulse 6s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    &--ondark {
      @apply text-white-80;
    }

    &--onlight {
      @apply text-gray opacity-40;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-75;
    }

    &--portrait {
      right: 15%;
    }

    &--medium {
      @apply opacity-40 scale-50;
      bottom: 1.875rem;
      right: 25%;

      svg {
        @apply scale-100;
      }
    }
    &--big {
      @apply opacity-40;
      bottom: 12.5rem;
      right: 25%;

      svg {
        @apply scale-100;
      }
    }
  }

  &.btn-main {
    @apply w-77 min-w-77 h-18 min-h-18 px-7 flex items-center justify-center border-2  rounded-full;

    .label {
      @apply uppercase text-3xl tracking-wide text-left font-semibold;
    }

    &.activated {
      // @apply border-gray;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;
    }

    &:disabled {
      @apply cursor-not-allowed;
    }

    &.btn-full {
      @apply w-full;
    }

    &--squared {
      @apply rounded-none py-2 h-auto;

      .label {
        @apply text-center;
      }
    }
    &--redBorder {
      @apply border-red;
    }
  }

  &.btn-menu {
    @apply w-auto h-14 min-h-14 px-6 flex flex-row items-center justify-between text-white;
    .icon {
      @apply text-white pr-4;
    }

    .label {
      @apply text-2xl uppercase font-bold tracking-wide;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;

      .icon {
      }

      .label {
      }
    }

    &--category {
      @apply px-6 mr-6;

      .label {
        @apply text-white font-bold tracking-tight;
      }
    }
  }

  &.btn-category {
    @apply w-full h-14 min-h-14 px-5 flex flex-row items-center justify-between;

    .label {
      @apply text-2xl text-left tracking-wide;
    }

    &.activated {
      // @apply border-gray;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;
    }

    &:disabled {
      @apply cursor-not-allowed;
    }
  }

  &.btn-mini-action {
    @apply w-14 h-25 flex flex-col items-center justify-center text-center;
    .icon {
      @apply w-full h-full flex bg-transparent justify-center items-center text-white;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;

      .icon {
        // @apply bg-primary-accent;
      }
    }

    &--primary {
      // @apply border-primary;

      .icon {
        // @apply bg-primary;
      }
    }

    &:disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }

  &.btn-card-cart {
    @apply w-full h-12  text-center  flex flex-col items-center justify-center;

    .icon {
      @apply mx-auto;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;
    }
  }

  &.btn-action-buy {
    @apply min-w-56 w-full h-18 flex p-1 items-center justify-center text-left;

    .label {
      @apply text-2xl uppercase font-bold text-center;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;

      .label {
        // @apply text-primary-accent;
      }
    }
  }

  &.btn-back {
    @apply w-auto h-12 flex flex-row items-center justify-start px-4;
    .icon {
      @apply text-white pr-4;
    }

    .label {
      @apply text-2xl uppercase font-bold text-center;
    }

    &:enabled:active {
      @apply drop-shadow-xl scale-95;
    }
  }

  &.btn-cat-box {
    @apply bg-white w-67 h-52 p-5 rounded-xl drop-shadow-xl flex flex-col items-center justify-start
    bg-gradient-to-br from-white via-white to-gray-ultra m-2;

    .icon {
      @apply w-29 h-29  mb-4 flex items-center justify-center overflow-hidden bg-cover bg-no-repeat bg-center;
      background-image: url("../cat-block-bg.png");

      span {
        @apply w-12 h-12 mx-auto;
      }
    }

    .label {
      @apply uppercase flex flex-grow items-center text-3xl  text-center font-bold;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.24);
    }

    &:enabled:active {
      @apply drop-shadow-2xl scale-95 bg-gradient-to-br from-gray-ultra via-gray-ultra to-gray-dark;
    }

    &--sm {
      @apply h-52;

      .icon {
        @apply w-16 h-16  mb-2;

        span {
          @apply w-11 h-11 mx-auto;
        }
      }
    }
  }
}

.sel-btn-2x2 {
  @apply flex;

  &:nth-child(1) {
    @apply justify-end;
  }
  &:nth-child(2) {
    @apply justify-start;
  }
  &:nth-child(3) {
    @apply justify-end;
  }
  &:nth-child(4) {
    @apply justify-start;
  }
}
.sel-btn-3x3 {
  @apply flex;

  &:nth-child(1) {
    @apply justify-end;
  }
  &:nth-child(2) {
    @apply justify-center;
  }
  &:nth-child(3) {
    @apply justify-start;
  }
  &:nth-child(4) {
    @apply justify-end;
  }
  &:nth-child(5) {
    @apply justify-center;
  }
  &:nth-child(6) {
    @apply justify-start;
  }
}

.sel-btn-4x4 {
  @apply flex;

  &:nth-child(1) {
    @apply justify-end;
  }
  &:nth-child(2) {
    @apply justify-center;
  }
  &:nth-child(3) {
    @apply justify-center;
  }
  &:nth-child(4) {
    @apply justify-start;
  }
  &:nth-child(5) {
    @apply justify-end;
  }
  &:nth-child(6) {
    @apply justify-center;
  }
  &:nth-child(7) {
    @apply justify-center;
  }
  &:nth-child(8) {
    @apply justify-start;
  }
}
