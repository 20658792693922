.hand_switcher {
  @apply flex items-center justify-center w-full;
  .dot {
    .option {
      @apply w-full h-full  rounded-full text-white flex flex-col items-center justify-center;
      &.option-A {
        @apply flex;
      }
      &.option-B {
        @apply hidden;
      }

      svg {
        width: 70%;
        height: 70%;
      }
    }
  }

  &.checked .dot {
    // @apply right-0 left-auto;
    &--normal {
      transform: translateX(50px);
    }

    .option {
      &.option-A {
        @apply hidden;
      }
      &.option-B {
        @apply flex;
      }
    }
  }

  &:active {
    @apply drop-shadow-xl;
  }
}


.lang_switcher {
  @apply flex items-center justify-center w-full;

  .dot {
    .option {
      @apply w-full h-full rounded-full text-white flex flex-col items-center justify-center;

      &.option-A {
        @apply flex;
      }

      &.option-B {
        @apply hidden;
      }

      svg {
        width: 70%;
        height: 70%;
      }
    }
  }

  &.checked .dot {

    // @apply right-0 left-auto;
    &--normal {
      transform: translateX(50px);
    }

    .option {
      &.option-A {
        @apply hidden;
      }

      &.option-B {
        @apply flex;
      }
    }
  }

  &:active {
    @apply drop-shadow-xl;
  }
}
