html,
body {
  @apply font-sans-default font-normal m-0 p-0 overflow-hidden;
  // width: var(--fullwidth);
  // height: var(--fullheight);
  font-size: var(--fontsizeglobal);
  overscroll-behavior: none !important;
  touch-action: none;
}

* {
  @apply box-border;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  // touch-action: pan-x pan-y;
  //
}

.no-radius {
  * {
    border-radius: 0 !important;

    &:after {
      border-radius: 0 !important;
    }
    &:before {
      border-radius: 0 !important;
    }
  }

  // .first-block:after,
  // .third-block:before, .success-block:before,  {
  //   border-radius: 0 !important;
  // }
}

a {
  text-decoration: none;
}
.cart-vertical-scroll {
  @apply pr-2 overflow-y-scroll overflow-x-hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-2.5;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-light rounded-full transition-all;
  }
}
.nex-error-vertical-scroll {
  @apply overflow-y-scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-0;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-light rounded-full transition-all;
  }
}
.nex-category-vertical-scroll {
  @apply overflow-y-scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-0;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-light rounded-full transition-all;
  }
}
.category-vertical-scroll {
  @apply pr-2 overflow-y-scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-2.5;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-light rounded-full transition-all;
  }
}
.category-vertical-scroll-sanusx {
  @apply pr-2 overflow-y-scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-4;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-light transition-all;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
  }
}

.menu_bar {
  @apply relative bg-gray-dark;
  &--r {
    &:before {
      content: "";
      @apply absolute block w-20 h-full  top-0 -left-20;
      background: inherit;
    }
  }
  &--l {
    &:after {
      content: "";
      @apply absolute block w-20 h-full top-0 -right-20;
      background: inherit;
    }
  }
}
.box-main {
  @apply relative z-5;
  // @apply relative z-5;

  *:not(.big-person-photo):not(.z-up) {
    // @apply relative z-10;
    @apply z-10;
  }

  &:before {
    // @apply w-full h-full block absolute top-0 left-0 z-0;
    // content: "";
    // background: radial-gradient(
    //   rgba(255, 255, 255, 0.5),
    //   rgba(255, 255, 255, 0)
    // );
  }
}

.big-person-photo {
  z-index: -1;

  img {
    mask-image: linear-gradient(to top, transparent 10%, black 30%);
  }
}

.wow {
  // visibility: hidden;
}

.mb-bg {
  @apply bg-cover;
  // background-image: url("../mb-bg.jpeg");
}
